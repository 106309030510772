import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { RichText } from "prismic-reactjs"
import styled from "styled-components"
import "react-responsive-carousel/lib/styles/carousel.min.css"
const Content2 = styled.div`
  margin: 0 auto 0 auto;
  a {
    color: #6594d4;
  }
`

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { arrival } = this.props.data.arrival.allAbouts.edges[0].node
    if (typeof window !== "undefined") {
      require("smooth-scroll")('a[href*="#"]', {
        offset: 80,
      })
    }

    return (
      <Layout location={this.props.location.pathname}>
        <SEO
          title="GLOBART ACADEMY "
          description="Die GLOBART Academy Triade LEBEN-MACHT-SINN! Die letzten Monate sind eine internationale Bestätigung: nun ist es Zeit über MACHT zu reden!"
          keywords={["GLOBART Academy", "Interaktiv", "Friedrich von Borries"]}
        />
        <Content2>
          <h1>Anfahrt</h1>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2657.813873604954!2d15.33131611565221!3d48.229458479230864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477264473ec4b5af%3A0xabf949a4b96c1bc1!2sStift%20Melk!5e0!3m2!1sde!2sat!4v1619251010943!5m2!1sde!2sat"
            width="100"
            height="450"
            allowfullscreen=""
            style={{ border: "0", width: "100%", height: "36vw" }}
            loading="lazy"
          ></iframe>
          <div id="arrival">{RichText.render(arrival)}</div>{" "}
        </Content2>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query ArrivalEnQuery {
    arrival: prismic {
      allAbouts(lang: "en-gb") {
        edges {
          node {
            arrival
          }
        }
      }
    }
  }
`
